import { AuthProvider } from '@vp/digital-auth-lib';
import type { Environment } from '@vp/digital-environment-lib';
import { RuntimeContextProvider } from '@vp/digital-environment-lib';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { RootLayout } from '@vp/digital-site-layout-lib';
import type { EnvironmentToTrackingMap, TrackingConfiguration } from '@vp/digital-tracking-lib';
import { BOOKENDS_SWAN_STYLES } from '@vp/esi-bookends';
import { SWAN_STYLE_KEY_MAP, SwanProvider, SwanSSRProvider, useBrowserClasses } from '@vp/swan';
import type { WrapPageElementBrowserArgs } from 'gatsby';
import { Link } from 'gatsby';
import type { ShapesModel } from 'models/ShapesModel';
import type { SelectOptionsModel } from 'models/selectOptionsModel';
import * as React from 'react';
import type { LocalizationProviderProps, LocalizedPageDataProps } from 'typings/localization';
import { DebugBanner } from './src/components/common/Debug/DebugBanner';
import './src/components/styles/common.scss';
import './src/components/styles/visage-overrides.scss';
import { AssetStoreProvider } from './src/contexts/AssetStoreProvider';
import BuildTimeData from './src/contexts/BuildTimeData';
import { IdentityProvider } from './src/contexts/IdentityContext';
import LocalizationProvider from './src/contexts/LocalizationProvider';
import { QRDataProvider } from './src/contexts/QRDataContext';
import PageWrapper from './src/pageLayoutWrappers/PageWrapper';

export interface PageContextType extends LocalizationProviderProps {
  externalUrls?: Array<any>;
  qrSelectOptions?: Array<SelectOptionsModel>;
  shapesData?: ShapesModel;
  locale: string;
  segmentKey: string;
  indexPage?: boolean;
  baseUrl: string;
  pagePath: string;
  environment: Environment;
  internalClientId: string;
  trackingKeys: EnvironmentToTrackingMap;
  localizedPageData: LocalizedPageDataProps;
  trackingConfiguration: TrackingConfiguration;
  workspaceUrl: string;
  tenant: string;
  useBookends: boolean;
  useSlimBookendsHeader?: boolean;
}

export const StyleKeys = [
  SWAN_STYLE_KEY_MAP.core,
  SWAN_STYLE_KEY_MAP.icon,
  SWAN_STYLE_KEY_MAP.spinner,
  SWAN_STYLE_KEY_MAP.selectionSet,
  SWAN_STYLE_KEY_MAP.standardHero,
  SWAN_STYLE_KEY_MAP.accordion,
  SWAN_STYLE_KEY_MAP.alertBox,
  SWAN_STYLE_KEY_MAP.modalDialog,
  SWAN_STYLE_KEY_MAP.tabs,
  SWAN_STYLE_KEY_MAP.utility,
  SWAN_STYLE_KEY_MAP.toggleSwitch,
  SWAN_STYLE_KEY_MAP.button,
  SWAN_STYLE_KEY_MAP.standardTile,
  SWAN_STYLE_KEY_MAP.secondaryTile,
  SWAN_STYLE_KEY_MAP.grid,
  SWAN_STYLE_KEY_MAP.listbox,
  ...BOOKENDS_SWAN_STYLES,
];

export const SwanConfiguration: React.FC<any> = ({
  tenant,
  locale,
  children,
}: {
  tenant: string;
  locale: string;
  children: React.ReactElement;
}) => {
  useBrowserClasses(true);
  return (
    <SwanProvider swanTenant={tenant} swanLocale={locale} globalLinkComponent={Link}>
      <SwanSSRProvider>{children}</SwanSSRProvider>
    </SwanProvider>
  );
};

export const wrapPageElement = ({
  element,
  props,
}: WrapPageElementBrowserArgs<Record<string, unknown>, PageContextType>) => {
  const {
    externalUrls,
    qrSelectOptions,
    shapesData,
    indexPage,
    environment,
    locale,
    segmentKey,
    trackingConfiguration,
    tenant,
    translations,
    defaultLocaleTranslations,
    internalClientId,
    workspaceUrl,
    useBookends,
    useSlimBookendsHeader,
  } = props.pageContext;

  if (indexPage || !internalClientId) {
    return <>{element}</>;
  }

  return (
    <SwanConfiguration>
      <LocaleProvider rawLocaleIdentifier={locale}>
        <RootLayout swanStyleKeys={StyleKeys}>
          <RuntimeContextProvider getEnvironment={() => environment}>
            <LocalizationProvider
              locale={locale}
              translations={translations}
              defaultLocaleTranslations={defaultLocaleTranslations}
            >
              <AuthProvider directAccessClientId={internalClientId}>
                <IdentityProvider>
                  <DebugBanner />
                  <PageWrapper
                    trackingConfiguration={trackingConfiguration}
                    locale={locale}
                    tenant={tenant}
                    useBookends={useBookends}
                    useSlimBookendsHeader={!!useSlimBookendsHeader}
                  >
                    <BuildTimeData.Provider
                      value={{
                        qrCodeUrls: externalUrls,
                        qrSelectOptions: qrSelectOptions,
                        shapesData: shapesData,
                        segmentKey: segmentKey,
                        locale: locale,
                        workspaceUrl: workspaceUrl,
                      }}
                    >
                      <AssetStoreProvider>
                        <QRDataProvider>{element}</QRDataProvider>
                      </AssetStoreProvider>
                    </BuildTimeData.Provider>
                  </PageWrapper>
                </IdentityProvider>
              </AuthProvider>
            </LocalizationProvider>
          </RuntimeContextProvider>
        </RootLayout>
      </LocaleProvider>
    </SwanConfiguration>
  );
};
