module.exports = [{
      plugin: require('../node_modules/.pnpm/@vp+gatsby-plugin-static-site@1.4.37_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-d_n4sdg4oclboi6d5kwpyloorxau/node_modules/@vp/gatsby-plugin-static-site/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/@vp+digital-gatsby-plugin-rollbar-provider@0.18.0_s76lttx23357iibxqhumrnd42y/node_modules/@vp/digital-gatsby-plugin-rollbar-provider/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"43c47eba7c1a491185f8aa37d5153626","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"Staging","client":{"javascript":{"code_version":"c7d7c47","source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-use-query-params-v2@2.0.0_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__reac_3lyyzk7jyj2ugrqemu26mgq7je/node_modules/gatsby-plugin-use-query-params-v2/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__n4b2d6iwppiyf2fdt2hwumf5uq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
